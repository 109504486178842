import React from 'react';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';

const { SubMenu } = Menu;

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const { path } = useRouteMatch();
  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  return (
    <Menu
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      selectedKeys={
        // eslint-disable-next-line
        [location.pathname]
      }
    >
      <Menu.Item key={`${path}`} icon={!topMenu && <FeatherIcon icon="home" />}>
        <NavLink onClick={toggleCollapsed} to={`${path}`}>
          Inicio
        </NavLink>
      </Menu.Item>

      {/*<SubMenu key="catalogos" icon={!topMenu && <FeatherIcon icon="heart" />} title="Catalogo de cirugias">
        <Menu.Item key={`${path}/catalogos/nuevo`}>
          <NavLink onClick={toggleCollapsed} to={`${path}/catalogos/nuevo`}>
            Nueva cirugía de catálogo
          </NavLink>
        </Menu.Item>
        <Menu.Item key={`${path}/catalogos`}>
          <NavLink onClick={toggleCollapsed} to={`${path}/catalogos`}>
            Catálogo de cirugías
          </NavLink>
        </Menu.Item>
      </SubMenu>*/}

      <SubMenu key="categorias" icon={!topMenu && <FeatherIcon icon="tag" />} title="Categorías">
        <Menu.Item key={`${path}/categorias_insumos`}>
          <NavLink onClick={toggleCollapsed} to={`${path}/categorias_insumos`}>
            Categorías de Insumos
          </NavLink>
        </Menu.Item>
        <Menu.Item key={`${path}/categorias_proveedores`}>
          <NavLink onClick={toggleCollapsed} to={`${path}/categorias_proveedores`}>
            Categorías de Proveedores
          </NavLink>
        </Menu.Item>
      </SubMenu>

      {/*<SubMenu key="categoriasInsumos" icon={!topMenu && <FeatherIcon icon="tag" />} title="Categorías Insumos">
        <Menu.Item key={`${path}/categorias_insumos/nuevo`}>
          <NavLink onClick={toggleCollapsed} to={`${path}/categorias_insumos/nuevo`}>
            Nueva categoría
          </NavLink>
        </Menu.Item>
        <Menu.Item key={`${path}/categorias_insumos`}>
          <NavLink onClick={toggleCollapsed} to={`${path}/categorias_insumos`}>
            Categorías de Insumos
          </NavLink>
        </Menu.Item>
      </SubMenu>

      <SubMenu key="categoriasProveedores" icon={!topMenu && <FeatherIcon icon="tag" />} title="Categorías Proveedores">
        <Menu.Item key={`${path}/categorias_proveedores/nuevo`}>
          <NavLink onClick={toggleCollapsed} to={`${path}/categorias_proveedores/nuevo`}>
            Nueva categoría
          </NavLink>
        </Menu.Item>
        <Menu.Item key={`${path}/categorias_proveedores`}>
          <NavLink onClick={toggleCollapsed} to={`${path}/categorias_proveedores`}>
            Categorías de Proveedores
          </NavLink>
        </Menu.Item>
      </SubMenu>*/}

      <SubMenu key="cirugias" icon={!topMenu && <FeatherIcon icon="heart" />} title="Cirugías">
        <Menu.Item key={`${path}/cirugias/nuevo`}>
          <NavLink onClick={toggleCollapsed} to={`${path}/cirugias/nuevo`}>
            Nueva cirugía
          </NavLink>
        </Menu.Item>
        <Menu.Item key={`${path}/cirugias`}>
          <NavLink onClick={toggleCollapsed} to={`${path}/cirugias`}>
            Cirugías
          </NavLink>
        </Menu.Item>
      </SubMenu>

      {/*<SubMenu key="historiasClinicas" icon={!topMenu && <FeatherIcon icon="file-plus" />} title="Historias Clínicas">
        <Menu.Item key={`${path}/historias_clinicas/nuevo`}>
          <NavLink onClick={toggleCollapsed} to={`${path}/historias_clinicas/nuevo`}>
            Nueva historia
          </NavLink>
        </Menu.Item>
        <Menu.Item key={`${path}/historias_clinicas`}>
          <NavLink onClick={toggleCollapsed} to={`${path}/historias_clinicas`}>
            Historias Clínicas
          </NavLink>
        </Menu.Item>
      </SubMenu>*/}

      {/*<SubMenu key="entradasSalidas" icon={!topMenu && <FeatherIcon icon="grid" />} title="Entradas / Salidas">
        <Menu.Item key={`${path}/entradas_salidas`}>
          <NavLink onClick={toggleCollapsed} to={`${path}/entradas_salidas`}>
            Entradas / Salidas
          </NavLink>
        </Menu.Item>
      </SubMenu>*/}

      <SubMenu key="insumos" icon={!topMenu && <FeatherIcon icon="package" />} title="Insumos">
        <Menu.Item key={`${path}/insumos`}>
          <NavLink onClick={toggleCollapsed} to={`${path}/insumos`}>
            Insumos
          </NavLink>
        </Menu.Item>
        <Menu.Item key={`${path}/ingredientes_activos`}>
          <NavLink onClick={toggleCollapsed} to={`${path}/ingredientes_activos`}>
            Ingredientes activos
          </NavLink>
        </Menu.Item>
        <Menu.Item key={`${path}/cotizaciones_insumos`}>
          <NavLink onClick={toggleCollapsed} to={`${path}/cotizaciones_insumos`}>
            Cotizaciones y compras
          </NavLink>
        </Menu.Item>
        <Menu.Item key={`${path}/entradas_salidas`}>
          <NavLink onClick={toggleCollapsed} to={`${path}/entradas_salidas`}>
            Entradas / Salidas
          </NavLink>
        </Menu.Item>
        <Menu.Item key={`${path}/entradas_salidas/caducidades`}>
          <NavLink onClick={toggleCollapsed} to={`${path}/entradas_salidas/caducidades`}>
            Reporte de caducidades
          </NavLink>
        </Menu.Item>
      </SubMenu>

      <SubMenu key="pacientes" icon={!topMenu && <FeatherIcon icon="user-plus" />} title="Pacientes">
        <Menu.Item key={`${path}/pacientes/nuevo`}>
          <NavLink onClick={toggleCollapsed} to={`${path}/pacientes/nuevo`}>
            Nuevo paciente
          </NavLink>
        </Menu.Item>
        <Menu.Item key={`${path}/pacientes`}>
          <NavLink onClick={toggleCollapsed} to={`${path}/pacientes`}>
            Pacientes
          </NavLink>
        </Menu.Item>
      </SubMenu>

      <SubMenu key="proveedores" icon={!topMenu && <FeatherIcon icon="truck" />} title="Proveedores">
        <Menu.Item key={`${path}/proveedores/nuevo`}>
          <NavLink onClick={toggleCollapsed} to={`${path}/proveedores/nuevo`}>
            Nuevo proveedor
          </NavLink>
        </Menu.Item>
        <Menu.Item key={`${path}/proveedores`}>
          <NavLink onClick={toggleCollapsed} to={`${path}/proveedores`}>
            Proveedores
          </NavLink>
        </Menu.Item>
      </SubMenu>

      <SubMenu key="roles" icon={!topMenu && <FeatherIcon icon="slack" />} title="Roles">
        <Menu.Item key={`${path}/roles/nuevo`}>
          <NavLink onClick={toggleCollapsed} to={`${path}/roles/nuevo`}>
            Nuevo rol
          </NavLink>
        </Menu.Item>
        <Menu.Item key={`${path}/roles`}>
          <NavLink onClick={toggleCollapsed} to={`${path}/roles`}>
            Roles
          </NavLink>
        </Menu.Item>
      </SubMenu>

      <SubMenu key="usuarios" icon={!topMenu && <FeatherIcon icon="users" />} title="Usuarios">
        <Menu.Item key={`${path}/usuarios/nuevo`}>
          <NavLink onClick={toggleCollapsed} to={`${path}/usuarios/nuevo`}>
            Nuevo usuario
          </NavLink>
        </Menu.Item>
        <Menu.Item key={`${path}/usuarios`}>
          <NavLink onClick={toggleCollapsed} to={`${path}/usuarios`}>
            Usuarios
          </NavLink>
        </Menu.Item>
      </SubMenu>
      
      {/*<SubMenu key="consultas" icon={!topMenu && <FeatherIcon icon="heart" />} title="Consultas">
        <Menu.Item key={`${path}/consultas/nuevo`}>
          <NavLink onClick={toggleCollapsed} to={`${path}/consultas/nuevo`}>
            Nueva consulta
          </NavLink>
        </Menu.Item>
        <Menu.Item key={`${path}/consultas`}>
          <NavLink onClick={toggleCollapsed} to={`${path}/consultas`}>
            Consultas
          </NavLink>
        </Menu.Item>
      </SubMenu>*/}

    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
